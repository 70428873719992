<template>
  <div class="block__subtitle mb-40">{{ title }}</div>
  <div class="block__tabs mb-30">
    <VTabs :is-small="true" :items="adminDiagnostic"/>
  </div>
  <div class="block__control">
    <div class="block__field">
      <VSearch
        name="searchDiagnostic"
        placeholder="Поиск"
        class-input="input-border-all input-padding-25 input-height-48"
        v-model="input"
      />
    </div>
  </div>
  <router-view></router-view>
</template>

<script>
import VSearch from '@/components/ui/form/Search/VSearch'
import VTabs from '@/components/views/Tabs/VTabs'
import { adminDiagnostic } from '@/utils/tabs'
import { useFilter } from '@/use/Base/useFilter'
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup () {
    return {
      adminDiagnostic,
      ...useFilter()
    }
  },
  components: {
    VSearch,
    VTabs,
  }
}
</script>
