<template>
  <div :class="['tabs', icons ? 'tabs__width-100' : '']">
    <div class="tabs__inner" v-if="!useParams">
      <router-link
        :to="{ name: item.routeName }"
        :class="['tabs__item', isSmall ? 'small' : '']"
        v-for="item in items"
        :key="item.name"
        @click="setCurrentRoute(item.routeName)"
      >
        {{ item.name }}
      </router-link>
    </div>
    <div class="tabs__inner" v-else>
      <router-link
        :to="{ name: item.routeName, params: { id: getParamsId } }"
        :class="['tabs__item', isSmall ? 'small' : '']"
        v-for="item in items"
        :key="item.name"
        @click="setCurrentRoute(item.routeName)"
      >
        {{ item.name }}
      </router-link>
    </div>
  </div>
</template>

<script>

import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

export default {
  emits: ['select'],
  props: {
    items: {
      type: [Array, Object],
      required: true
    },
    isSmall: {
      type: Boolean,
      required: false,
      default: false
    },
    icons: {
      type: Boolean,
      required: false,
      default: false
    },
    useParams: {
      type: Boolean,
      required: false
    }
  },
  setup () {
    const route = useRoute()
    const store = useStore()
    const setCurrentRoute = (routeName) => {
      store.commit('setCurrentRoute', routeName)
    }

    const getParamsId = computed(() => {
      return route.params.id
    })

    return {
      getParamsId,
      setCurrentRoute
    }
  }
}
</script>
